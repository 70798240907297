<template>
  <v-flex class="ma-2">
    <v-form  v-model="valid" ref="form">
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <v-text-field
              v-model="siswaModified.fullName"
              :rules="[required]"
              label="Nama Lengkap"
              dense
              hide-details
              @blur="validateSiswa"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-text-field
              v-model="siswaModified.phone"
              prepend-icon="mdi-phone-classic"
              label="Nomor HP(user)"
              dense
              hide-details
              @blur="validateSiswa"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" sm="4" md="4">
          <v-text-field
              v-model="siswaModified.nickName"
              label="Nama Panggilan"
              dense
              hide-details
              @blur="validateSiswa"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="4" md="2">
          <v-switch
              v-model="siswaModified.sex"
              :label="siswaModified.sex?'Laki-laki':'Perempuan'"
              x-small
          ></v-switch>
        </v-col>
        <v-col cols="6" sm="4" md="3">
          <v-switch
              v-model="siswaModified.menikah"
              :label="siswaModified.menikah?'Menikah':'Belum Menikah'"
              x-small
          ></v-switch>
        </v-col>

      </v-row>

      <v-row>
        <v-col cols="12" sm="4" md="4">
          <v-text-field
              v-model="siswaModified.birthPlace"
              label="Tempat Lahir"
              dense
              hide-details
              @blur="validateSiswa"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4">

          <v-menu
              v-model="menuDate1"
              :close-on-content-click="false"
              max-width="290"
          >
            <template v-slot:activator="{ on, attrs } ">
              <v-text-field
                  :value="computedDateFormattedDatefns(siswaModified.birthDate)"
                  prepend-inner-icon="mdi-calendar"
                  clearable
                  label="Tanggal Lahir"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="siswaModified.birthDate = null"
                  dense
                  hide-details
              ></v-text-field>
            </template>

            <v-date-picker
                v-model="siswaModified.birthDate"
                @change="menuDate1 = false"
            ></v-date-picker>
          </v-menu>

        </v-col>
        <v-col cols="12" sm="2" md="2">
          <v-text-field
              v-model="siswaModified.anakKe"
              dense
              hide-details
              prefix="Anak ke-"
              type="number"
              @blur="validateSiswa"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" md="4">
          <v-text-field
              v-model="siswaModified.address1"
              label="Jalan, RT/RW"
              dense
              hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-text-field
              v-model="siswaModified.address2"
              label="Desa"
              dense
              hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-text-field
              v-model="siswaModified.address3"
              label="Kecamatan"
              dense
              hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <v-text-field
              v-model="siswaModified.city"
              @blur="validateSiswa"
              label="Kab/Kota"
              dense
              hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-text-field
              v-model="siswaModified.state"
              @blur="validateSiswa"
              label="Propinsi"
              dense
              hide-details
          ></v-text-field>
        </v-col>
      </v-row>


      <v-row class="blue lighten-5">
        <v-col cols="12" sm="12" md="4">
          <v-text-field
              v-model="siswaModified.fatherName"
              @blur="validateSiswa"
              label="Nama Ayah"
              dense
              hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-text-field
              v-model="siswaModified.fatherJob"
              @blur="validateSiswa"
              label="Pekerjaan Ayah"
              dense
              hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-text-field
              v-model="siswaModified.fatherPhone"
              @blur="validateSiswa"
              label="Telpon Ayah"
              dense
              hide-details
              prepend-inner-icon="mdi-phone-classic"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="orange lighten-5">
        <v-col cols="12" sm="12" md="4">
          <v-text-field
              v-model="siswaModified.motherName"
              @blur="validateSiswa"
              label="Nama Ibu"
              dense
              hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-text-field
              v-model="siswaModified.motherJob"
              label="Pekerjaan Ibu"
              @blur="validateSiswa"
              dense
              hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-text-field
              v-model="siswaModified.motherPhone"
              label="Telpon Ibu"
              dense
              hide-details
              prepend-inner-icon="mdi-phone-classic"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="grey lighten-4">
        <v-col cols="12" sm="4" md="4">
          <v-text-field
              v-model="siswaModified.parAddress1"
              @blur="validateSiswa"
              label="Jln-RT/RW-Desa"
              dense
              hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-text-field
              v-model="siswaModified.parAddress2"
              label="Kecamatan Ortu"
              @blur="validateSiswa"
              dense
              hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-text-field
              v-model="siswaModified.parCity"
              label="Kota Ortu"
              @blur="validateSiswa"
              dense
              hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-text-field
              v-model="siswaModified.parState"
              label="Propinsi Ortu"
              @blur="validateSiswa"
              dense
              hide-details
          ></v-text-field>
        </v-col>
      </v-row>


      <v-row class="red lighten-4">
        <v-col cols="12" sm="3" md="3">
          <v-text-field
              v-model="siswaModified.daerahSmbg"
              @blur="validateSiswa"
              label="Daerah Sambung"
              dense
              hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-text-field
              v-model="siswaModified.desaSmbg"
              label="Desa Sambung"
              @blur="validateSiswa"
              dense
              hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-text-field
              v-model="siswaModified.kelompokSmbg"
              label="Kelompok"
              @blur="validateSiswa"
              dense
              hide-details
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="3" md="3">
          <v-text-field
              v-model="siswaModified.kiKelompok"
              label="KI Klp"
              @blur="validateSiswa"
              dense
              hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-text-field
              v-model="siswaModified.telpKiKelompok"
              label="Telp KI Klp"
              @blur="validateSiswa"
              dense
              hide-details
              prepend-inner-icon="mdi-phone-classic"
          ></v-text-field>
        </v-col>
      </v-row>


      <v-snackbar
          v-model="snackbar"
      >
        {{ snackBarMesage }}
        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>

    </v-form>
  </v-flex>
</template>

<script>
import ERole from "@/models/e-role";
import FSiswa from "@/models/f-siswa";
import User from "@/models/user";
import {format, parseISO} from "date-fns";


export default {
  components: {
  },
  props:{
    validStep2: Boolean,
    userModified: new User(),
    siswaModified: new FSiswa(),
    fkurikulumBean: Object,
    fdivisionBean: Object,
    itemsFDivision: [],
  },
  data() {
    return {
      show1: false,

      date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,

      snackBarMesage: '',
      snackbar: false,

      itemsRoles:[
        ERole.ROLE_ADMIN,
        ERole.ROLE_USER,
        ERole.ROLE_USER_1,
      ],
      itemsOrganizationLevel:[
        'CORP',
        'DIV'
      ],

      valid: false,
      userStatus: 0, //0.belum validate user //1. user akun sudah dipakai orang //2.valid user lama //3. valid user baru


    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },

    validateSiswa(){
      if (this.$refs.form.validate()) {
          this.$emit("validateStep2", true )
      }else {
        this.$emit("validateStep2", false )
      }
    },

    lookupImageUrl(){
      if (this.previewImage===undefined || this.previewImage==='' ){
        return '../../assets/images/account_icon.png'
      }else {
        return this.previewImage
      }
    },
    lookupImageUrlLazy(){
      return '../../assets/images/account_icon.png'
    },

    required: function(value) {
      try {
        if (value!==undefined) {
          return true;
        } else {
          return 'tidak boleh kosong';
        }
      }catch (e) {
        return true
      }
    },
    min3: function(value) {
      try{
        if (value.length >= 3 ) {
          return true;
        } else {
          return 'harus lebih dari 5 huruf.';
        }
      }catch (e) {
        return true
      }
    }

  }
}
</script>

<style scoped>

</style>