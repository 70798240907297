import axios from 'axios';
import authHeader from "@/services/auth-header";
import ConstApiUrls from "@/services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FSiswaService {
    getApiUrl() {
        return API_URL
    }
    getAllFSiswa(){
        return axios.get(API_URL + `getAllFSiswa`, { headers: authHeader() });
    }
    getAllFSiswaContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFSiswa`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFSiswaContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFSiswaById(id){
        return axios.get(API_URL + `getFSiswaById/${id}`, { headers: authHeader() });
    }
    getFSiswaByIdPublic(id){
        return axios.get(API_URL + `public/getFSiswaById/${id}`, { headers: authHeader() });
    }
    updateFSiswa(item){
        return axios.put(API_URL + `updateFSiswa/${item.id}`, item, {headers: authHeader()})
    }
    updateFSiswaPulic(item){
        return axios.put(API_URL + `public/updateFSiswa/${item.id}`, item, {headers: authHeader()})
    }
    createFSiswa(item){
        return axios.post(API_URL + `createFSiswa`, item, {headers: authHeader()})
    }
    createFSiswaPublic(item){
        return axios.post(API_URL + `public/createFSiswa`, item, {headers: authHeader()})
    }
    deleteFSiswa(id){
        return axios.delete(API_URL + `deleteFSiswa/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFSiswa(itemIds){
        // let user = JSON.parse(localStorage.getItem('user'));
        // return axios.delete(API_URL + `deleteAllFSiswa`, {
        //     headers:  authHeaderMultipart(),
        //     data: {
        //         message: 'delete  success'
        //     }
        // });
        return axios.delete(API_URL + `deleteAllFSiswa`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FSiswaService()