<template>
  <div>
    <v-stepper v-model="e1" class="elevation-0 mt-2">

    <v-stepper-header class="elevation-0">
      <v-stepper-step
          :complete="e1 > 1"
          step="1"
      >
        Buat Akun
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
          :complete="e1 > 2"
          step="2"
      >
        Data Pribadi
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3">
        Berkas dan Kesehatan
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card
            class="mb-12"
            elevation="0"
            min-height="400"
        >
          <PpdbMarketItemUser
              :validStep1="validStep1"
              :userModified="userModified"
              :fkurikulumBean="fkurikulumBean"
              :fdivisionBean="fdivisionBean"
              @validateStep1="validateStep1"
          ></PpdbMarketItemUser>
        </v-card>

        <v-btn
            color="primary"
            @click="continueAccount"
            :disabled="!validStep1"
        >
          Selanjutnya
        </v-btn>

      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card
            class="mb-12"
            elevation="0"
            min-height="400"
        >
        <PpdbMarketItemSiswa
            :validStep2="validStep2"
            :siswaModified="siswaModified"
            :userModified="userModified"
            :fkurikulumBean="fkurikulumBean"
            :fdivisionBean="fdivisionBean"
            @validateStep2="validateStep2"
        ></PpdbMarketItemSiswa>
        </v-card>

        <v-btn
            color="primary"
            @click="continueSiswa"
            :disabled="!validStep2"
        >
          Selanjutnya
        </v-btn>

<!--        <v-btn text>-->
<!--          Cancel-->
<!--        </v-btn>-->
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card
          class="mb-12 ma-2"
          color="grey lighten-1"
          min-height="300px"
        >
          <h2 class="justify-center">SEDANG PROSES PENDAFTARAN</h2>
          <h1 class="blue--text">{{ fkurikulumBean.description }}</h1>
          <div class="mt-4"></div>
          <div v-if="messageBerhasil !== '' ">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <span class="blue-grey--text subtitle-1">NOMOR PENDAFTARAN ANDA:</span>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <span class="ml-4 display-3 blue--text font-weight-bold">#{{ messageBerhasil }}</span>
              </v-col>
            </v-row>
          </div>
          <div v-if="messageNama !== '' ">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <span class="blue-grey--text subtitle-1">ATAS NAMA:</span>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <span class="ml-4 display-2 black--text font-weight-bold">#{{ messageNama }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <span class="font-weight-black">
                  SILAHKAN MENUNGGU KONFIRMASI
                </span>
              </v-col>
            </v-row>
          </div>

        </v-card>

        <v-btn
            color="red"
            @click="prosesDaftar"
            :disabled="messageBerhasil !== '' "
        >
          Daftar Sekarang
          <v-icon small>mdi-walk</v-icon>
        </v-btn>

<!--        <v-btn text>-->
<!--          Cancel-->
<!--        </v-btn>-->

      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>

    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </div>

</template>

<script>
import PpdbMarketItemUser from "@/components/ppdb/market/PpdbMarketItemUser";
import PpdbMarketItemSiswa from "@/components/ppdb/market/PpdbMarketItemSiswa";
import User from "@/models/user";
import FSiswa from "@/models/f-siswa";
import FDivisionService from "../../../services/apiservices/f-division-service"
import FKurikulumService from "../../../services/apiservices/f-kurikulum-service"
import AuthService from "@/services/auth-service";
import UserService from "../../../services/user-service"
import ERole from "@/models/e-role";
import UploadService from "@/services/apiservices/file-upload-service";
import FSiswaService from "../../../services/apiservices/f-siswa-service"
import FtKrsService from "../../../services/apiservices/ft-krs-service"
import FtKrs from "@/models/ft-krs";

export default {
  name: "PublicPpdbMarketItem",
  components:{
    PpdbMarketItemUser,
    PpdbMarketItemSiswa
  },
  data(){
    return{
      snackBarMesage: '',
      snackbar: false,

      messageBerhasil: '',
      messageNama: '',

      e1 : 1,
      validStep1: false,
      validStep2: false,

      userStatus: 0, //0.belum validate user //1. user akun sudah dipakai orang //2.valid user lama //3. valid user baru

      fkurikulumBean: undefined,
      fdivisionBean: undefined,
      userModified: new User(),
      siswaModified: new FSiswa(),

    }
  },
  methods:{
    continueAccount(){
      if (this.validStep1 ===true){
        const roles = [ERole.ROLE_SISWA]
        this.userModified.roles = roles
        this.userModified.fdivisionBean = this.fdivisionBean.id
        this.userModified.organizationLevel = "DIV"

        // console.log(JSON.stringify(this.userModified))

        UploadService.uploadImage(this.userModified.currentImage, (event) => {
          // this.progress = Math.round((100 * event.loaded) / event.total);
          event.toString()
        })
            .then(response => {
              const responseFileName = response.data.message
              this.userModified.avatarImage = responseFileName

              AuthService.register(this.userModified).then(
                  () =>{
                    this.e1 +=1
                    this.siswaModified.fullName = this.userModified.fullName
                    this.siswaModified.phone = this.userModified.username

                  },
                  error => {
                    console.log(error)
                  }
              )

            })
            .catch((err) => {
              this.snackBarMesage = "Could not upload the image! " + err;
              this.snackbar =true
            });


      }

    },

    continueSiswa(){
      this.siswaModified.fdivisionBean = this.fdivisionBean.id
      this.siswaModified.statSiswa = "PPDB"
      this.siswaModified.avatarImage = this.userModified.avatarImage
      // console.log(JSON.stringify(this.siswaModified))

      if (this.siswaModified.id >0){
        FSiswaService.updateFSiswaPulic(this.siswaModified).then(
            response => {
              this.siswaModified = response.data
              // console.log(`>>>> ${JSON.stringify(this.siswaModified)} ###`)

              this.e1 +=1
            }
        );
      }else {
        FSiswaService.createFSiswaPublic(this.siswaModified).then(
            response =>{
              this.siswaModified = response.data
              // console.log(`>>>> ${JSON.stringify(this.siswaModified)} ###`)

              // this.userModified.roles = this.rolesToArray(this.userModified.roles)
              this.userModified.roles = ["DIV"]
              this.userModified.idsiswa = this.siswaModified.id

              console.log(JSON.stringify(this.userModified))
              AuthService.updateUser(this.userModified).then(
                  responseUser =>{
                    this.userModified = responseUser.data
                    this.e1 +=1
                  },
                  error => {
                    console.log(error)
                  }
              )
            }
        );
      }


    },

    prosesDaftar(){

      FtKrsService.getAllFtKrsByParentPublic(this.siswaModified.id, this.fkurikulumBean.id).then(
          response=>{
            console.log(response.data)
            if (response.data.length===0){
              this.createKrs()
            }else {
              this.updateKrs(response.data[0])
            }

          },
          error=>{
            console.log(error)
          }
      )

    },

    createKrs(){
      const ftKrsBean = new FtKrs()
      ftKrsBean.fdivisionBean = this.fdivisionBean.id
      ftKrsBean.fsiswaBean = this.siswaModified.id
      ftKrsBean.fkurikulumBean = this.fkurikulumBean.id

      FtKrsService.createFtKrsPublic(ftKrsBean).then(
          response=>{
            console.log(response.data)
            this.messageBerhasil = response.data.kode1
            this.messageNama = this.siswaModified.fullName
          },
          error=>{
            console.log(error)
          }
      )


    },
    updateKrs(ftKrsBean){
      ftKrsBean.toString()

      // console.log(`TIDAK BOLEH DAFTAR LAGI  ${JSON.stringify(ftKrsBean)}`)

      this.messageBerhasil = ftKrsBean.kode1
      this.messageNama = this.siswaModified.fullName

    },


    rolesToArray(roles){
      var arrRoles = []
      roles.forEach(
          role=>{
            arrRoles.push(role.name);
          }
      )
      return arrRoles
    },
    validateStep1(value){
      this.validStep1 = true
      this.userStatus = value

      if (this.userStatus===2){
        this.snackBarMesage = "Anda sudah mempunyai akun"
        this.snackbar = true

        UserService.getUserByUsername(this.userModified.username).then(
            response=>{
              this.userModified = response.data
              this.e1 +=1
              // console.log(JSON.stringify(this.userModified.idsiswa))
              // console.log(JSON.stringify(this.userModified))

              if (this.userModified.idsiswa >0){
                FSiswaService.getFSiswaByIdPublic(this.userModified.idsiswa).then(
                    responseSiswa =>{
                      this.siswaModified = responseSiswa.data
                      // console.log(this.siswaModified)
                      this.validStep2 = true
                    }
                )
              }else {
                this.siswaModified.fullName = this.userModified.fullName
                this.siswaModified.phone = this.userModified.username
              }

              // console.log(JSON.stringify(this.userModified))
            }
        )

      }
    },
    validateStep2(value){
      this.validStep2 = value
      console.log(value)
    },


  },
  mounted() {
    const id = this.$route.params.id

    FKurikulumService.getFKurikulumById(id).then(
        response => {
          this.fkurikulumBean = response.data

          FDivisionService.getFDivisionById(this.fkurikulumBean.fdivisionBean).then(
              response => {
                this.fdivisionBean = response.data
              },
              error=> {
                console.log(error)
              }
          )

        },
        error=> {
          console.log(error)
        }
    )
  }
}
</script>

<style scoped>

</style>