export default class FtKrs {
  constructor(
    id,
    kode1,
    notes,
    fdivisionBean,

    statSelected,
    selectedDate,
    statCancel,
    cancelDate,

    enumStatApproval,
    approveDate,
    approveBy,

    enumStatKelulusan,
    kelulusanDate,
    diluluskanOleh,

    fkurikulumBean,
    fsiswaBean,
    fsiswaRes,


    created,
    modified,
    modifiedBy
  ) {
    this.id = id;
    this.kode1 = kode1;
    this.notes = notes;
    this.fdivisionBean = fdivisionBean;


    this.statSelected = statSelected;
    this.selectedDate = selectedDate;
    this.statCancel = statCancel;
    this.cancelDate = cancelDate;

    this.enumStatApproval = enumStatApproval;
    this.approveDate = approveDate;
    this.approveBy = approveBy;

    this.enumStatKelulusan = enumStatKelulusan;
    this.kelulusanDate = kelulusanDate;
    this.diluluskanOleh = diluluskanOleh;

    this.fkurikulumBean = fkurikulumBean;
    this.fsiswaBean = fsiswaBean;
    this.fsiswaRes = fsiswaRes;

    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}
