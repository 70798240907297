<template>
  <v-flex>
    <v-form  v-model="valid" ref="form">
      <v-row>
        <v-col
            cols="12"
            sm="12"
            md="4"
        >
          <v-text-field
              v-model="userModified.username"
              :rules="[required, min3]"
              prepend-icon="mdi-phone-classic"
              label="Nomor HP(username)"
              type="number"
          ></v-text-field>

        </v-col>
        <v-col
            cols="12"
            sm="12"
            md="4"
        >
          <v-text-field
              v-model="userModified.password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              label="Password"
              hide-details="auto"
              @click:append="show1 = !show1"
              :rules="[required, min3]"
              clearable
              @blur="validateUserName"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            sm="6"
            md="4"
        >
          <v-text-field
              prepend-inner-icon="mdi-email"
              v-model="userModified.email"
              :rules="[required]"
              label="Email"
              @blur="validateUserName"
              dense
              hide-details
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            sm="12"
            md="4"
        >
          <v-text-field
              v-model="userModified.fullName"
              label="Nama Lengkap"
              :rules="[required]"
              dense
              hide-details
          ></v-text-field>
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="8">
          <v-flex class="d-flex justify-center">

                <v-hover v-slot="{ hover }">
                  <v-card
                      :elevation="hover ? 10 : 1"
                      :class="[{ 'on-hover': hover }, hover?'card-hover-opacity':'card-not-hover-opacity']"
                  >
                    <v-img
                        :lazy-src="lookupImageUrlLazy(userModified)"
                        :src="lookupImageUrl(userModified)"
                        height="225px"
                    >
                      <v-card-title class="text-h6 white--text fill-height">

                        <v-row
                            class="fill-height flex-column"
                            justify="space-between"
                        >
                          <v-spacer/>
                          <div class="align-self-center">
                            <v-btn
                                :class="{ 'show-btns': hover }"
                                :color="transparent"
                                icon
                                large
                                dark
                                outlined
                                @click="showDialogUpload"
                            >
                              <v-icon
                                  :class="{ 'show-btns': hover }"
                                  :color="transparent"
                                  large
                              >
                                mdi-upload
                              </v-icon>
                            </v-btn>
                          </div>
                        </v-row>

                      </v-card-title>
                    </v-img>

                  </v-card>
                </v-hover>

        </v-flex>
        </v-col>
      </v-row>
      <input ref="refFileInput" type="file" @change="onFileChanged" v-show="false">


      <v-row class="pl-4 pr-4" v-show="false">
        <v-col cols="8" sm="8" md="8" class="blue lighten-5 ">
          <div> Sudah mempunyai akun
            <v-btn outlined route to="/login" class="green--text text-decoration-none ml-4" plain >
              Login <v-icon x-small>mdi-lock</v-icon>
            </v-btn></div>
        </v-col>
      </v-row>

      <v-snackbar
          v-model="snackbar"
      >
        {{ snackBarMesage }}
        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>

    </v-form>
  </v-flex>
</template>

<script>
import ERole from "@/models/e-role";
import User from "@/models/user";
import FUserService from "../../../services/user-service"
import AuthService from "../../../services/auth-service"


export default {
  name: "PpdbMarketItemUser",
  components: {
  },
  props:{
    validStep1: Boolean,
    userModified: new User(),
    fkurikulumBean: Object,
    fdivisionBean: Object,
    itemsFDivision: [],
  },
  data() {
    return {
      show1: false,

      snackBarMesage: '',
      snackbar: false,

      itemsRoles:[
        ERole.ROLE_ADMIN,
        ERole.ROLE_USER,
        ERole.ROLE_USER_1,
      ],
      itemsOrganizationLevel:[
        'CORP',
        'DIV'
      ],
      // siswaModified: new User(),
      currentImage: undefined,
      previewImage: undefined,

      valid: false,
      userStatus: 0, //0.belum validate user //1. user akun sudah dipakai orang //2.valid user lama //3. valid user baru

      transparent: 'rgba(255, 255, 255, 0)',

    }
  },
  methods: {
    selectImage(image) {
      this.currentImage = image;
      this.previewImage = URL.createObjectURL(this.currentImage);
      this.progress = 0;
      this.message = "";
    },
    showDialogUpload(){
      // if (this.siswaModified.username !==undefined ){
      //
      //   if (this.siswaModified.id===0){
      //     // this.saveCreateOnly()
      //   }
      //   this.$refs.refUploadDialog.showDialog()
      //
      // }else{
      //   this.snackBarMesage = 'Deskripsi dan Divisi harus diisi dahulu'
      //   this.snackbar = true
      // }
      this.$refs.refFileInput.click()
    },
    onFileChanged(e){
      this.currentImage = e.target.files[0]
      this.previewImage = URL.createObjectURL(this.currentImage);
      this.validateUserName()
    },

    validateUserName(){
      if (this.userModified.username && this.userModified.password) {
        AuthService.validate(this.userModified).then(
            ()  => {
                this.userStatus = 2 //valid user lama
                this.$emit("validateStep1", this.userStatus )
            },
            () => {
              FUserService.getUserByUsername(this.userModified.username).then(
                  () => {
                    this.userStatus = 1 //ser akun sudah dipakai orang
                  },
                  () => {
                    if (this.$refs.form.validate()) {
                      if (this.currentImage !==undefined){
                        this.userModified.currentImage = this.currentImage //currentImage beda dengan namaFile

                        this.userStatus =3 //valid untuk menjadi user baru
                        this.$emit("validateStep1", this.userStatus )
                      }
                    }
                  }
              )
            }
        );

      }

    },

    lookupImageUrl(){
      if (this.previewImage===undefined || this.previewImage==='' ){
        return '../../assets/images/account_icon.png'
      }else {
        return this.previewImage
      }
    },
    lookupImageUrlLazy(){
      return '../../assets/images/account_icon.png'
    },

    required: function(value) {
      try {
        if (value!==undefined) {
          return true;
        } else {
          return 'tidak boleh kosong';
        }
      }catch (e) {
        return true
      }
    },
    min3: function(value) {
      try{
        if (value.length >= 3 ) {
          return true;
        } else {
          return 'harus lebih dari 5 huruf.';
        }
      }catch (e) {
        return true
      }
    }

  }
}
</script>

<style scoped>
.show-btns {
  color: blue !important;
  opacity: 1;
}
</style>