export default class FSiswa {
  constructor(
    id,
    kode1,
    description,
    fdivisionBean,
    statusActive,
    avatarImage,

    statSiswa,

    regDate,
    nis,
    fullName,
    nickName,
    birthPlace,
    birthDate,
    sex,
    menikah,
    anakKe,
    dariKe,

    address1,
    address2,
    address3,
    city,
    state,
    phone,
    email,

    fatherName,
    fatherJob,
    fatherPhone,

    motherName,
    motherJob,
    motherPhone,

    parAddress1,
    parAddress2,
    parCity,
    parState,

    imageName,
    imageIdentity,
    notes1,
    notes2,
    notes3,

    daerahSmbg,
    desaSmbg,
    kelompokSmbg,
    kiKelompok,
    telpKiKelompok,

    created,
    modified,
    modifiedBy

  ) {

    this.id = id;
    this.kode1 = kode1;
    this.description = description;
    this.statusActive = statusActive;
    this.fdivisionBean = fdivisionBean;
    this.avatarImage = avatarImage;

    this.statSiswa = statSiswa;

    this.regDate = regDate;
    this.nis = nis;
    this.fullName = fullName;
    this.nickName = nickName;
    this.birthPlace = birthPlace;
    this.birthDate = birthDate;
    this.sex  =sex;
    this.menikah  =menikah;
    this.anakKe = anakKe;
    this.dariKe = dariKe;

    this.address1 = address1;
    this.address2 = address2;
    this.address3 = address3;
    this.city = city;
    this.state = state;
    this.phone = phone;
    this.email = email;

    this.fatherName = fatherName;
    this.fatherJob = fatherJob;
    this.fatherPhone = fatherPhone;

    this.motherName = motherName;
    this.motherJob = motherJob;
    this.motherPhone = motherPhone;

    this.parAddress1 = parAddress1;
    this.parAddress2 = parAddress2;
    this.parCity = parCity;
    this.parState = parState;

    this.imageName = imageName;
    this.imageIdentity = imageIdentity;
    this.notes1 = notes1;
    this.notes2 = notes2;
    this.notes3 = notes3;

    this.daerahSmbg = daerahSmbg;
    this.desaSmbg = desaSmbg;
    this.kelompokSmbg = kelompokSmbg;
    this.kiKelompok = kiKelompok;
    this.telpKiKelompok = telpKiKelompok;

    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}
