<template>
  <PublicPpdbMarketItem
  ></PublicPpdbMarketItem>
</template>

<script>
import PublicPpdbMarketItem from "@/components/ppdb/market/PpdbMarketItem";
export default {
  components:{
    PublicPpdbMarketItem
  },
  data () {
    return {
      e1: 1,
    }
  },

}
</script>

<style scoped>

</style>